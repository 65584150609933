import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./stegovnopravo.style.scss";
import Slika from "../../assets/stegovno/gradjevina.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
export default function StegovnoPravo() {
  return (
    <Container className="stegovno-container">
      <Helmet>
        <meta
          name="description"
          content="Stegovno pravo, područje prakse odvjetničkog ureda Gelo, usko je vezano uz radno pravo te utječe na ugled, čast i dostojanstvo stranke u stegovnom postupku."
        />
        <title>Stegovno pravo | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="stegovno-title">Stegovno Pravo </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="stegovno-para">
            Stegovno pravo i radno pravo su usko vezani. Pravni akti koji
            reguliraju stegovne postupke u pravilu predviđaju pokretanje
            stegovnih postupaka neovisno o tome da li je u kaznenom ili
            prekršajnom postupku osoba proglašena krivom, te vežu za sebe, u
            pravilu, i udaljenje iz službe do okončanja stegovnog, prekršajnog
            ili kaznenog postupka. Na ovaj način stranci se već samim udaljenjem
            iz službe presumira krivnja i nameće prekomjeran teret, prije svega
            u pogledu smanjenja plaće.
          </p>
          <Link to="../kontakt">
            <Button>Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="stegovno-img-container">
            <img src={Slika} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="stegovno-para">
            Stegovni postupci su postupci koji se u najvećem broju slučajeva
            nalaze na samoj ivici prava i sloboda zajamčenih Ustavom Republike
            Hrvatske. Naime, stegovni postupci se mogu pokrenuti kod samog
            postojanja sumnje na počinjenje stegovnog prijestupa, što samo po
            sebi nije u skladu sa pravnim poretkom Republike Hrvatske gdje je
            svatko nedužan i ne može ga se smatrati krivim dok mu se pravomoćnom
            presudom ne utvrdi krivnja, svako ograničenje slobode i prava mora
            biti razmjerno naravi potrebe za ograničenjem te se svakome jamči
            štovanje i pravna zaštita njegova osobnog i obiteljskog života,
            dostojanstva, ugleda i časti. Obzirom na posljedice stegovnih
            postupaka istima se ni u kojem slučaju ne smije pristupati kao
            postupcima koji su jednostavni ili manje bitni.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
