import React from "react";
import "./obiteljskopravo.style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Biljeske from "../../assets/obiteljsko/biljeske.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function ObiteljskoPravo() {
  return (
    <Container className="obiteljsko-container">
      <Helmet>
        <meta
          name="description"
          content=" Iako bi odnosi između bračnih drugova trebali biti okončani razvodom, nastavljaju se određeni sudski postupci. Odvjetnički ured Gelo specijalizira se za ovo područje."
        />
        <title>Obiteljsko pravo | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="obiteljsko-title">Obiteljsko pravo</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="obiteljsko-para">
            Odnosi između bračni drugova trebali bi biti okončani razvodom
            braka, ali s obzirom da li u braku ima zajedničke djece i bračne
            stečevine nastavljaju se u sudskim postupcima bilo radi utvrđenja
            bračne stečevine, uzdržavanja, roditeljske skrbi, susreta i druženja
            djece s roditeljem s kojim ne žive itd.
          </p>

          <p className="obiteljsko-para">
            Ne ulazeći u razloge razvoda braka, zaboravlja se da nigdje u zakonu
            ne piše da djeca poslije razvoda braka ne mogu živjeti sa ocem a da
            majka plaća uzdržavanje, stoga se već u početku sudskog postupka
            kreće od pretpostavke da je djeci prirodnije da žive sa majkom. Za
            ovakav stav uvelike su zaslužni i očevi koji ne žele preuzeti
            odgovornost i obvezu već istu prebacuju na majku. Uzdržavanje je
            poseban problem jer bez obzira na postojanje minimalnih novčanih
            iznosa za uzdržavanje te tablica sa prosječnim mjesečnim potrebama
            djeteta, često se javljaju dvije situacije; jedna kada roditelj koji
            je obvezan na uzdržavanje isto izbjegava čak i bez obzira na
            potencijalni kazneni postupak protiv njega, druga kada roditelj s
            obzirom na svoja primanja ne može podnijeti teret uzdržavanja koji
            bude previsoko određen.
          </p>
          <Link to="../kontakt">
            <Button>Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="obiteljsko-image-container">
            <img src={Biljeske} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="obiteljsko-para">
            Bračna stečevina je institut koji bračnim drugovima uvelike pomaže
            za vrijeme zajedničkog života ali i koji istima pravi velike
            probleme nakon razvoda braka. Ovdje bi posebno istaknuo slijedeće
            situacije; npr. kada bračni drugovi zajednička sredstva ulažu u
            nekretninu koja predstavlja vlastitu imovinu jednog od njih ili
            njihovih roditelja, kada je za vrijeme braka samo jedan bračni drug
            zaposlen, kada bračni drugovi svoju nekretninu vode na imenu samo
            jednog bračnog druga koji pred razvod braka raspolaže s njom na
            štetu drugog bračnog druga (npr. kupoprodaja, darovanje, založno
            pravo, služnost itd.), kada jedan bračni drug za vrijeme braka
            stekne nekretninu za koju drugi bračni drug ne zna te ju vodi kao
            vlastitu imovinu itd. Bez obzira na prethodno fokusiranje na brak i
            bračne drugove, situacija nije ništa drugačija ni kod izvanbračne
            zajednice koja uistinu ima donekle drugačije pretpostavke i
            okolnosti ali u pravilu iste probleme.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
