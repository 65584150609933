import React from "react";
import "./documents.style.scss";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { AiFillFilePdf } from "react-icons/ai";

export default function Dokumenti() {
  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          content="Kontaktirati odvjetnički ured Gelo možete putem email adrese, putem mobilnog telefona ili posjetom na lokaciju ureda u Vinkovcima - Ulica Kneza Mislava 5A"
        />
        <title>Dokumenti | Odvjetnički ured Gelo</title>
      </Helmet>
      <div className="mainrow">
        <div className="middle">
          <a
            href="https://pdfhost.io/v/RFsVCgR6n_1_TARIFA_O_NAGRADAMA_I_NAKNADI_TROKOVA_ZA_RAD_ODVJETNIKA_2012"
            target="_blank"
            download="tarifa.pdf"
          >
            1. TARIFA O NAGRADAMA I NAKNADI TROŠKOVA ZA RAD ODVJETNIKA 2012
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>

        <div className="middle">
          <a
            href="https://pdfhost.io/v/5iWrcP4dw4_2_TARIFA_O_NAGRADAMA_I_NAKNADI_TROKOVA_2014"
            target="_blank"
          >
            2. TARIFA O NAGRADAMA I NAKNADI TROŠKOVA
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/x0895CZ3V_3_IZMJENE_I_DOPUNE_TARIFE_O_NAGRADAMA_I_NAKNADI_TROKOVA_10314_NN"
            target="_blank"
          >
            3. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA 103-14 NN
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/TkHhmVjFK_4_IZMJENE_I_DOPUNE_TARIFE_O_NAGRADAMA_I_NAKNADI_TROKOVA_11814_NN"
            target="_blank"
          >
            4. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA 118-14 NN
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/jQPVNyU56__5_IZMJENE_I_DOPUNE_TARIFE_O_NAGRADAMA_I_NAKNADI_TROKOVA_2015"
            target="_blank"
          >
            5. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA 2015
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/DbmSfupku_6_IZMJENE_I_DOPUNE_TARIFE_O_NAGRADAMA_I_NAKNADI_TROKOVA_2022"
            target="_blank"
          >
            6. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA 2022
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/x7CkiCIiY_OBAVIJEST_O_OBRADI_OSOBNIH_PODATAKA"
            target="_blank"
          >
            7. OBAVIJEST O OBRADI OSOBNIH PODATAKA
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/ylRJ3JdMt_IzmjenaTarifeonagradamainaknaditroskovazaradodvjetnikaNarodnenovinebroj126222"
            target="_blank"
          >
            8. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA ZA RAD
            ODVJETNIKA 126-22 NN
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/Nfz3093d3_IzmjeneidopunaTarifeonagradamainaknaditroskovazaradodvjetnika2"
            target="_blank"
          >
            9. IZMJENE I DOPUNE TARIFE O NAGRADAMA I NAKNADI TROŠKOVA ZA RAD
            ODVJETNIKA 37-22 NN
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
        <div className="middle">
          <a
            href="https://pdfhost.io/v/Z.oFJRMRo_PUNOMO_Odvjetnik_Hrvoje_Gelo"
            target="_blank"
          >
            10. PUNOMOĆ – ODVJETNIK HRVOJE GELO
          </a>
          <AiFillFilePdf className="pdf-icon" />
        </div>
      </div>
    </Container>
  );
}
