import { Route, Routes } from "react-router-dom";
import React from "react";
import Navbar from "./Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import NasljednoPravo from "./pages/NasljednoPravo/NasljednoPravo";
import RadnoPravo from "./pages/RadnoPravo/RadnoPravo";
import StegovnoPravo from "./pages/StegovnoPravo/StegovnoPravo";
import UpravnoPravo from "./pages/UpravnoPravo/UpravnoPravo";
import ZemljoknjiznoPravo from "./pages/ZemljoknjiznoPravo/ZemljoknjiznoPravo";
import UgovoriPravo from "./pages/UgovoriPravo/UgovoriPravo";
import ObiteljskoPravo from "./pages/ObiteljskoPravo/ObiteljskoPravo";
import Dokumenti from "./pages/Documents/Documents";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/pravo/zemljisnoknjizno-pravo-nekretnine"
          element={<ZemljoknjiznoPravo />}
        />
        <Route
          path="/pravo/nasljedno-pravo-ostavinski-postupak"
          element={<NasljednoPravo />}
        />
        <Route path="/pravo/ugovori-obvezno-pravo" element={<UgovoriPravo />} />
        <Route path="/pravo/obiteljsko-pravo" element={<ObiteljskoPravo />} />
        <Route path="/pravo/radno-pravo" element={<RadnoPravo />} />
        <Route path="/pravo/stegovno-pravo" element={<StegovnoPravo />} />
        <Route path="/pravo/upravno-pravo" element={<UpravnoPravo />} />
        <Route path="/osobno" element={<About />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/dokumenti" element={<Dokumenti />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
