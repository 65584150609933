import React from "react";
import "./about.style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import GeloImg from "../../assets/onama/Hrvoje Gelo.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function About() {
  return (
    <Container className="about-container">
      <Helmet>
        <meta
          name="description"
          content="Odvjetnički ured Gelo utemeljen je u Vinkovcima te se specijalizira u nekoliko područja prakse. Kontaktirati odvjetnički ured možete pozivom na 0981723456."
        />
        <title>O nama | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row className="about-intro-section">
        <Col sm={12} md={12} lg={6}>
          <h1 className="about-title">O nama</h1>
          <p className="about-text">
            Odvjetnik Hrvoje Gelo diplomirao je 2012. godine na Pravnom
            fakultetu Sveučilišta Josipa Jurja Strossmayera u Osijeku.
          </p>

          <p className="about-text">
            Državni stručni ispit položio je 2016. godine pred Državnom ispitnom
            komisijom pri Ministarstvu uprave, dok je pravosudni ispit položio
            2019. godine pred Ispitnim povjerenstvom za polaganje pravosudnog
            ispita pri Ministarstvu pravosuđa.
          </p>
          <p className="about-text">
            Samostalni odvjetnički ured otvorio je 2021. godine.
          </p>
          <p className="about-text">
            Područja prakse uključuju:
            <ul>
              <li>Zemljišnoknjižno pravo i nekretnine</li>
              <li>Ugovori i obvezno pravo</li>
              <li>Nasljedno pravo i ostavinski postupak</li>
              <li>Obiteljsko pravo</li>
              <li>Radno pravo</li>
              <li>Stegovno pravo</li>
              <li>Upravno pravo</li>
            </ul>
          </p>

          <Link to="../kontakt">
            <Button className="about-contact-btn">Kontaktiraj nas</Button>
          </Link>
        </Col>
        <Col sm={12} md={12} lg={6} className="about-img-container">
          <div>
            <img src={GeloImg} className="gelo" alt="Hrvoje Gelo" />
          </div>
        </Col>
      </Row>
      <Row className="about-title-row">
        <h2 className="about-section-title">
          Profesionalna načela kojima se vodi i u koja vjeruje su:
        </h2>
      </Row>
      <Row className="marginizer">
        <Col md={12} lg={6} className="col1">
          <div className="s1">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">I</span>
              </div>
              <div className="principles-text">
                Odvjetništvo je ustavna kategorija, što dovoljno govori o tome
                da isto ne predstavlja posao koji se obavlja već poziv na koji
                se odaziva
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className="col1">
          <div className="s2">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">II</span>
              </div>
              <div className="principles-text">
                Stranka ne plaća odvjetnika za izvršene usluge već ga nagrađuje
                što štiti njena prava i interese
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="marginizer">
        <Col md={12} lg={6} className="col1">
          <div className="s3">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">III</span>
              </div>
              <div className="principles-text">
                Sjedište ureda ne smije ograničavati odvjetnika u vršenju
                njegovoga poziva
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className="col1">
          <div className="s4">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">IV</span>
              </div>
              <div className="principles-text">
                Odvjetnik mora biti upoznat sa običajima koji vrijede na
                području gdje zastupa svoju stranku
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="marginizer">
        <Col md={12} lg={6} className="col1">
          <div className="s5">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">V</span>
              </div>
              <div className="principles-text">
                Odvjetnik se u odnosu prema vlastitoj stranci mora pronaći u
                ulozi zaštitnika njenih prava, pri čemu mora zadržati svoju
                nezavisnost
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className="col1">
          <div className="s6">
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">VI</span>
              </div>
              <div className="principles-text">
                Odvjetnik štiti prava stranke na isti način kako bi štitio i
                svoja prava
              </div>
            </div>
          </div>
        </Col>
      </Row>{" "}
      <Row className="marginizer">
        <Col md={12} lg={6} className="col1">
          <div>
            <div className="principles-wrapper">
              <div className="number-wrapper">
                <span className="numberCircle">VII</span>
              </div>
              <div className="principles-text">
                Odvjetnik je dužan uvijek koristiti se svim dopuštenim
                sredstvima kako bi se stranci zaštitila prava što brže i sa što
                manje troškova
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
