import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./zemljoknjiznopravo.style.scss";
import Knjiga from "../../assets/zemljoknjizno/knjiznica.png";
import Biljeznica from "../../assets/zemljoknjizno/biljeznica.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ZemljoknjiznoPravo() {
  return (
    <Container className="zemljoknjizno-container">
      <Helmet>
        <meta
          name="description"
          content="Neusklađenost zemljišnoknjižnih podataka i katastarskih podataka dovodi do niza sudskih postupaka. Odvjetnički ured Gelo specijalizira se u ovom području prakse."
        />
        <title>
          Zemljišnoknjižno pravo i nekretnine | Odvjetnički ured Gelo
        </title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="zemljoknjizno-title">
            Zemljišnoknjižno pravo i nekretnine{" "}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="zemljoknjizno-para">
            Neusklađenost zemljišnoknjižnih podataka i katastarskih podataka,
            posebno na području Dalmacije dovodi do niza dugotrajnih sudskih
            postupaka. Naime, već sam čin nasljeđivanja ili kupoprodaje
            nekretnine stvara problem, jer se kao vlasnici ili posjednici vode
            osobe koje su davno preminule ili osobe koje nikada nisu ni bili
            stvarni vlasnici ili posjednici. Dodatni problem je stvoren samim
            činom da su se suvlasnici, u prošlosti, između sebe podijelili i kao
            takvi niz godina koristili dijelove određene nekretnine, kojima su
            nadjenuli i imena (npr. pojata, gumno, put), da bi onda u oporukama
            ili rješenjima o nasljeđivanju dobili dijelove nekretnine koji se ne
            mogu uknjižiti u redovnom postupku.
          </p>
          <Link to="../kontakt">
            <Button className="zemljoknjizno-contact-btn">
              Kontaktiraj nas
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="zemljoknjizno-img-container">
            <img src={Knjiga} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="zemljoknjizno-para">
            Neki od uzroka nastanka ovakvog stanja su i činjenice da vlasnici
            nisu vršili upise svog vlasništva jer im ga primjerice nitko nije
            sporio, jer su vršili zamjene nekretnina između rodbine i
            prijatelja, jer su stjecali nekretnine kao protuuslugu za izvršeni
            rad itd. Poseban slučaj neusklađenosti odnosi se na činjenicu da je
            Republika Hrvatska upisana kao vlasnik, još uvijek na niz
            nekretnina, u zemljišnim knjigama dok su stvarni vlasnici upisani
            kao posjednici u katastru ili su tamo upisani njihovi prednici.
          </p>
          <p className="zemljoknjizno-para">
            S druge strane, neupisani stvarni tereti, posebno služnosti, podloga
            su za dugotrajne sudske postupke upravo jer stranke ne vrše dubinsku
            analizu zemljišnoknjižnog stanja već postupaju sa načelom povjerenja
            u zemljišne knjige koje načelo je i dalje samo „obično“ načelo koje
            se kao takvo najčešće ruši institutom dosjelosti. Navedeni institut
            dosjelosti je institut koji je veliki broj nekretnina, upravo zbog
            ovoga povjerenja, učinio bezvrijednima i njihove vlasnike uveo u niz
            sudskih postupaka kojima nisu svi jednako dorasli, prvenstveno pri
            tome misleći na potrebu za razumijevanjem lokalnih običaja i pravila
            ponašanja koja su vrijedila u vrijeme nastanka međusobnih odnosa.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="zemljoknjizno-img-container">
            <img src={Biljeznica} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="zemljoknjizno-para">
            Kako sastavljanju samih ugovora ili isprava, tako i pokretanju
            parničnih i izvanparničnih postupaka mora prethoditi dubinska
            analiza zemljišnoknjižnog stanja, počevši od državnih arhiva,
            katastra pa do prikupljanja informacija od stranaka (na koji način
            su naslijedili, od koga su kupili, kako su obrađivali, kada su se
            podijelili itd.) jer bez obzira što Republika Hrvatska predstavlja
            jednu cjelinu nikada se ne smije zaboraviti da običaji nisu isti
            primjerice u Dalmaciji i Slavoniji, te je upravo ta razlika jedan od
            kamena spoticanja ujednačene sudske prakse.
          </p>

          <p className="zemljoknjizno-para">
            Ovakvo stanje je glavna kočnica razvoja jer stvara pravnu
            nesigurnost u postupanju s nekretninama, međutim uz stručnu pravnu
            pomoć nema nerješivih problema, ukoliko se stranci na samome početku
            ukaže na eventualnu razliku između onoga što želi i onoga što može
            ostvariti.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
