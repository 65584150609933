import React from "react";
import "./contact.style.scss";
import Container from "react-bootstrap/Container";
import Kontakt from "../../assets/kontakt/gelo.jpg";
import { Helmet } from "react-helmet";
function Contact() {
  return (
    <Container className="contact-container">
      <Helmet>
        <meta
          name="description"
          content="Kontaktirati odvjetnički ured Gelo možete putem email adrese, putem mobilnog telefona ili posjetom na lokaciju ureda u Vinkovcima - Ulica Kneza Mislava 5A"
        />
        <title>Kontakt | Odvjetnički ured Gelo</title>
      </Helmet>
      <div className="wrapper">
        <div className="k-detail">
          <h1 className="header">Kontakt</h1>
          <span className="txt">
            Odvjetnik Hrvoje Gelo <br />
            Kneza Mislava 5A, 32100 Vinkovci
          </span>

          <div className="k-detail2">
            <span className="txt">
              Telefon: <strong>+385 (0) 981723456</strong>
            </span>
            <span className="txt">
              Email: <strong>gelohrvoje@gmail.com</strong>
            </span>
          </div>
        </div>
        <img src={Kontakt} className="img" alt="contact" />
      </div>
    </Container>
  );
}

export default Contact;
