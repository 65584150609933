import React from "react";
import "./nasljednopravo.style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pero from "../../assets/nasljedno/pero.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function NasljednoPravo() {
  return (
    <Container className="nasljedno-container">
      <Helmet>
        <meta
          name="description"
          content="Iako zvuči jednostavno, u praksi nastaju komplikacije. Odvjetnički ured Gelo specijalizira se u području nasljednog prava i ostavinskog postupka."
        />
        <title>
          Nasljedno pravo i ostavinski postupak | Odvjetnički ured Gelo
        </title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="nasljedno-title">
            Nasljedno pravo i ostavinski postupak
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="nasljedno-para">
            Nasljeđuje se zbog smrti fizičke osobe i u trenutku njene smrti, na
            temelju oporuke ili na temelju zakona. Međutim koliko god to zvučalo
            jednostavno praksa je potpuno drugačija. Najveći problemi i
            najkompliciraniji sudski postupci nastaju kada se kod sudionika
            postupka do smrti ostavitelja sve „podrazumijevalo“. Ovdje je
            svakako potrebno istaknuti situacije kao što su npr. jedan od
            nasljednika sa ostaviteljem živi u kući koju je nasljednik sa
            vlastitim sredstvima nadogradio, uredio, povećao joj vrijednost da
            bi se nakon smrti (bez oporuke) ista po zakonu imala dijeliti među
            nasljednicima na jednake dijelove, zatim npr. ostavitelj nije
            ostavio pisanu oporuku ali je „prigodno“ neposredno pred smrt usmeno
            oporučio pred svjedocima u korist jednog od nasljednika ili treće
            osobe, isto tako npr. roditelji sklope međusobne ugovore o
            doživotnom uzdržavanju kako bi iz nasljeđivanja isključili djecu kao
            zakonske nasljednike, ili sklapanje ugovora o doživotnom uzdržavanju
            sa djecom kako bi se isključilo od nasljedstva bračnog druga itd.
          </p>
          <Link to="../kontakt">
            <Button>Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="nasljedno-pravo-img-container">
            <img src={Pero} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="nasljedno-para">
            Bez obzira što bi ostavinski postupak trebao biti vođen uz
            poštovanje prema ostavitelju (oporučitelju) i njegovoj volji to
            često nije tako, bilo voljom samih nasljednika ili trećih osoba.
            Stoga uloga odvjetnika u ovakvim sudskim postupcima mora biti ne
            samo da štiti prava i interese svoje stranke već i da, koliko je to
            moguće, spriječi i izbjegne dodatno narušavanje odnosa među
            nasljednicima.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
