import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import "./footer.style.scss";
import { Link } from "react-router-dom";
import Logo from "./../../assets/footer-logo.svg";
export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="footer-wrapper">
      <div className="footer-row-1">
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/zemljisnoknjizno-pravo-nekretnine"
        >
          Zemljišnoknjižno pravo i nekretnine
        </Link>
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/nasljedno-pravo-ostavinski-postupak"
        >
          Nasljedno pravo i ostavinski postupak
        </Link>
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/ugovori-obvezno-pravo"
        >
          Ugovori i obvezno pravo
        </Link>
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/obiteljsko-pravo"
        >
          Obiteljsko pravo
        </Link>
      </div>
      <div className="footer-row-2">
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/radno-pravo"
        >
          Radno pravo
        </Link>
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/stegovno-pravo"
        >
          Stegovno pravo
        </Link>
        <Link
          onClick={useEffect}
          className="footer-link"
          to="/pravo/upravno-pravo"
        >
          Upravno pravo
        </Link>
        <Link onClick={useEffect} className="footer-link" to="/kontakt">
          Kontakt
        </Link>
      </div>
      <div className="footer-row-3">
        <img src={Logo} className="logo" alt="logo" />
      </div>
    </div>
  );
}
