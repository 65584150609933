import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./radnopravo.style.scss";
import Ured from "../../assets/radno/ured.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
export default function RadnoPravo() {
  return (
    <Container className="radno-container">
      <Helmet>
        <meta
          name="description"
          conent="Ugovori o radu, kolektivni ugovori, diskriminacija, odmori, dopusti, plaće naknade, sindikati, naknade štete - odvjetnički ured Gelo specijalizira se u području radnog prava."
        />
        <title>Radno pravo | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="radno-title">Radno pravo </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="radno-para">
            Radno pravo je grana prava s kojom se stranke susreću samo u
            iznimnim slučajevima i to samo onda kada se pojavi opasnost od
            gubitka radnoga mjesta, što je svakako pogrešno. Ugovori o radu,
            kolektivni ugovori, diskriminacija i uznemiravanje, odmori i
            dopusti, plaće i naknade, nesposobnost za rad, naknade štete,
            zabrana utakmice, radnička vijeća, sindikati i udruge poslodavaca,
            prekršaji, inspekcijski nadzor, samo su neki od svakodnevnih
            problema.
          </p>

          <p className="radno-para">
            Navedeni problemi nisu samo problemi radnika već i poslodavaca.
            Upravo stoga je bitno da se reagira na vrijeme u skladu sa
            propisanim postupkom i prema nadležnim tijelima. Dolazak kod
            odvjetnika na pravno savjetovanje u pogledu problema nastalog na
            radnom mjestu ili u vezi sa radom ne mora nužno značiti pokretanje
            sudskog postupka već upravo suprotno.
          </p>

          <Link to="../kontakt">
            <Button>Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="radno-img-container">
            <img src={Ured} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="radno-para">
            Uvriježeno je mišljenje da je radnik slabija strana koju poslodavac
            iskorištava, pri čemu se zaboravlja na čitav niz sudskih postupaka
            koji su neosnovano pokrenuti protiv poslodavca i koji su poslodavcu
            nanijeli nenadoknadivu štetu, prije svega u pogledu povrede ugleda u
            poslovnome svijetu. Radno pravo je dvosmjerna cesta kojom se obje
            strane moraju pažljivo kretati da bi obje postigle svoj cilj.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
