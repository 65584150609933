import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./upravnopravo.style.scss";
import Stup from "../../assets/upravno/stupovi.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function UpravnoPravo() {
  return (
    <Container className="upravno-container">
      <Helmet>
        <meta
          name="description"
          content="Upravno pravo jedno je od područja prakse odvjetničkog ureda Gelo - uključujući sve sve aktivnosti javnopravnih tijela u upravnom postupku."
        />
        <title>Upravno pravo | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="upravno-title">Upravno pravo </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="upravno-para">
            Upravno pravo koristimo u svakodnevnom životu iako to u većini
            slučajeva niti neshvaćamo, jer se radi o postupcima koji se
            „podrazumijevaju“. Upravnom stvari smatra se svaka stvar u kojoj
            javnopravno tijelo (tijela državne uprave, tijela jedinica lokalne i
            područne samouprave, pravne osobe koje imaju javne ovlasti) u
            upravnom postupku rješava o pravima, obvezama ili pravnim interesima
            stranaka.
          </p>
          <Link to="../kontakt">
            <Button>Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="upravno-img-container">
            <img src={Stup} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="upravno-para">
            Iako to u praksi nije slučaj, javnopravna tijela dužna su strankama
            omogućiti da što lakše zaštite i ostvare svoja prava, kao i da se
            brinu da neznanje odnosno neukost stranke i drugih osoba koje
            sudjeluju u postupku ne bude na štetu prava koja im po zakonu
            pripadaju. U upravnim stvarima bi se trebalo postupati što je moguće
            jednostavnije, bez odgode i uz što manje troškova, međutim upravo
            ovdje se javlja najveći problem a to je onaj čuveni „nedostaje vam
            još...“.
          </p>

          <p className="upravno-para">
            Stranke pravilno pristupaju javnopravnom tijelu kao tijelu koje bi
            im trebalo riješiti problem ili im pomoći u rješavanju problema, ali
            se ubrzo pronađu u situaciji u kojoj shvate da se same neće moći
            obratiti javnopravnom tijelu sa pravilno postavljenim zahtjevom ili
            da neće moći udovoljiti svim traženjima javnopravnog tijela. Bez
            pravilno postavljenog zahtjeva, bez pridržavanja rokova i
            udovoljavanja opravdanim traženjima javnopravnog tijela na pravilan
            način, ne može se očekivati da će pokretanje upravnog spora, radi
            ocjene zakonitosti pojedinačne odluke, postupanja, propuštanja
            javnopravnog tijela odnosno ocjene zakonitosti sklapanja, raskidanja
            i izvršavanja upravnog ugovora, rezultirati uspjehom za stranku
            odnosno ostvarenjem ili zaštitom njenih prava.
          </p>

          <p className="upravno-para">
            Obraćanje odvjetniku prije pokretanja upravnog postupka, bilo u vidu
            pravnog savjetovanja ili u vidu zastupanja zasigurno će olakšati i
            ubrzati postupak, svakako ukoliko isti ocjeni da je zahtjev stranke
            opravdan.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
