import { NavLink } from "react-router-dom";
import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Logo from "./assets/logo2.png";
import { Link } from "react-router-dom";

function NavbarPage() {
  return (
    <Navbar fixed="top" bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">
          <Link to="./">
            <img src={Logo} className="logo" alt="navbar_logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-center">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "500px" }}
            navbarScroll
          >
            <Nav.Link as={NavLink} to={"/"}>
              Početna
            </Nav.Link>
            <Nav.Link as={NavLink} to={"/osobno"}>
              Osobno
            </Nav.Link>
            <NavDropdown title="Pravo" id="navbarScrollingDropdown">
              <NavDropdown.Item
                as={NavLink}
                to={"/pravo/zemljisnoknjizno-pravo-nekretnine"}
              >
                Zemljišnoknjižno pravo i nekretnine
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={"/pravo/nasljedno-pravo-ostavinski-postupak"}
              >
                Nasljedno pravo i ostavinski postupak
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={"/pravo/ugovori-obvezno-pravo"}
              >
                Ugovori i obvezno pravo
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/pravo/obiteljsko-pravo"}>
                Obiteljsko pravo
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/pravo/radno-pravo"}>
                Radno pravo
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/pravo/stegovno-pravo"}>
                Stegovno pravo
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/pravo/upravno-pravo"}>
                Upravno pravo
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to={"/kontakt"}>
              Kontakt
            </Nav.Link>
            <Nav.Link as={NavLink} to={"/dokumenti"}>
              Dokumenti
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarPage;
