import React from "react";
import "./ugovoripravo.style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Malj from "../../assets/ugovori/malj pravde.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function UgovoriPravo() {
  return (
    <Container className="ugovori-container">
      <Helmet>
        <meta
          name="description"
          content="U praksi se stranke često susreću sa šprancama ugovora koje su ili preopćenite ili su ih pisali nadripisari. Odvjetniki ured Gelo specijalizira se u ovom području."
        />
        <title>Ugovori i obvezno pravo | Odvjetnički ured Gelo</title>
      </Helmet>
      <Row>
        <Col>
          <h1 className="ugovori-title">Ugovori i obvezno pravo</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ugovori-para">
            U praksi se stranke često susreću sa šprancama ugovora koje su ili
            preopćenite ili su ih pisali nadripisari ili što je nerijetko slučaj
            sa šprancama koje budu dostupne (iako ne bi smjele) u određenim
            „neodvjetničkim“ uredima i kao takve budu prihvaćene kao „zasigurno
            ispravne“.
          </p>
          <p className="ugovori-para">
            Ugovori sklopljeni po ovakvim šprancama u pravilu budu najskuplji
            ugovori jer su kod sastavljanja u načelu besplatni a kod izvršavanja
            obveza iz njih nastanu sudski sporovi. Da bi ugovor (predugovor) bio
            takav da štiti sve strane odnosno da onemogući nepotrebne sporove i
            neizvršavanje obveza, samo sastavljanje ugovora mora biti proces
            kojemu se mora pristupiti na isti način na koji se pristupa stranci
            u sudskom postupku, stoga presudni podaci za sastavljanje ugovora
            nisu oni koje mora sadržavati svaki ugovor ili oni koje mora
            sadržavati određena vrsta ugovora, već upravo oni za koje stranka
            smatra da nisu bitni.
          </p>
          <Link to="../kontakt">
            <Button className="ugovori-contact-btn">Kontaktiraj nas</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="ugovori-img-container">
            <img src={Malj} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ugovori-para">
            Obvezno pravo obuhvaća ne samo ugovorne odnose već i izvanugovorne
            odnose, počevši od štete (imovinske i neimovinske), krivnje,
            odgovornosti maloljetnika, nužne obrane, stanja nužde, dopuštene
            samopomoći, odgovornost za drugoga, solidarna odgovorost,
            odgovornost poslodavca, odgovornost pravne osobe, oslobođenje od
            odgovornosti, odgovornost za nespravan proizvod, naknade štete u
            slučaju tjelesne ozljede, narušenja zdravlja, izgubljene zarade,
            troškova liječenja, pogreba, stjecanje bez osnove ili osnove koja je
            kasnije otpala, poslovodstvo bez naloga, itd.
          </p>
          <p className="ugovori-para">
            Ovdje je posebno bitno za istaknuti postupke naknade šteta koje
            stranke često pokušaju same voditi čime se u pravilu same stavljaju
            u nepovoljan položaj odnosno u položaj „slabije strane“. Nije nužno
            da svaki postupak naknade štete dobije epilog sudskog postupka,
            ukoliko se od početka vodi argumentirano, pripremljeno i usmjereno.
            Stoga se i sklapanje nagodbi ne smije tumačiti kao poraz, posebno
            ukoliko prihvatimo kao činjenicu da je u samoj sudskoj praksi
            zauzeto shvaćanje da se suđenjem u razumnom roku smatra ono koje
            traje najviše tri godine u prvostupanjskom postupku i isto toliko u
            drugostupanjskom postupku. Međutim, bez obzira na duljinu trajanja
            postupka, da li stranka želi nagodbu ili ne mora biti
            isključivonjena odluka.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
