import React from "react";
import "./home.style.scss";
import { Helmet } from "react-helmet";
import LogoFront from "../../assets/front-logo.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Helmet>
        <style>{"body { background-color: #1C1D1E; }"}</style>
        <style>{".footer-wrapper {display: none; }"}</style>
      </Helmet>
      <Container className="home-container">
        <Row>
          <Col lg={12}>
            <div className="img-wrapper">
              <img src={LogoFront} alt="logo" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="btn-wrap-home">
              <Link to="../kontakt">
                <Button>Kontaktirajte nas</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
